.CoverComponent {
  display: flex;
  justify-content: space-around;
  padding-bottom: 2vw;
}

.CoverComponent .CollectionLink {
  box-shadow: 0 0 5px rgb(32, 32, 32);
}

.CoverComponent .Description {
  display: inline-block;
  width: 50vw;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin: auto 0; */
  padding: 2vw 5vw;
  line-height: 1.5em;
  height: auto;
}

.CoverComponent .Description > a > h3 {
  width: 100%;
  text-transform: capitalize;
  font-size: 2.5vw;
  text-shadow: 0px 0px 2px black;
  margin: 5vh 0 2vh 0;
  /* font-style: italic; */
}

.CoverComponent .Description > p {
  color: black;
  font-size: 1.3vw;
  line-height: 1.3em;
  letter-spacing: 0.05em;
  text-shadow: 0px 0px 1px black;
  text-align: justify;
}