.CollectionsPage {
  background-color: rgba(64, 64, 64, 0.5);
  margin: 2vw;
  box-shadow: 0 0 10px black;
  padding: 2vw 2vw 0 2vw;
}

.CollectionsPage  > h3 {
  text-align: center;
  color: black;
  font-size: 3vw;
  text-shadow: 0px 0px 2px black;
  margin-bottom: 2vw;
}