.CollectionPage {
  background-color: rgba(64, 64, 64, 0.5);
  padding-top: 3vh;
}

.CollectionPage > h3 {
  text-transform: capitalize;
  text-align: center;
  color: black;
  font-size: 3vw;
  text-shadow: 1px 1px 3px darkslategray;
  margin-bottom: 2vw;
}

div.fade.modal.show {
  background-color: rgba(255, 255, 255, 0.5);
}

div.modal-content {
  background-color: rgb(40, 40, 40);
  color: white;
  text-shadow: 0 0 3px white;
}

div.modal-header button.close span {
  color: white;
}

div.modal-title.h4 {
  font-family: 'Abel', sans-serif;
}

div.modal-body {
  margin: -1px 0;
  background-color: rgb(50, 50, 50);
  text-shadow: none;
}

div.modal-body video {
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .CollectionPage > div > h3 {
    font-size: 9vw;
  }
}