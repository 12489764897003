.ContactPage {
  padding: 5vh 20vw;
  background: rgba(32, 32, 32, 0.85);
  color: white;
  min-height: calc(100vh - 62px);
}

@media screen and (max-width: 500px) {
  .ContactPage {
    padding: 5vh 4vw;
  }
}

.ContactPage h2 {
  text-align: center;
}

.ContactPage table:not(.contact-info) td {
  display: block;
}

.ContactPage input:not(#submit),
.ContactPage textarea {
  display: block;
  width: 100%;
}

.ContactPage #submit {
  width: 25%;
  min-width: 120px;
  margin: 2vh;
}