.WorkComponent {
  display: flex;
  justify-content: space-around;
  padding-bottom: 10vw;
}

.WorkComponent .WorkImage {
  box-shadow: 0 0 5px black;
}

.WorkComponent .WorkImage:hover {
  box-shadow: 0 0 5px black;
  cursor: pointer;
}

.WorkComponent .Description {
  display: inline-block;
  width: 40vw;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4vw;
  line-height: 1.5em;
}

.WorkComponent .Description > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.WorkComponent .Description h3 {
  width: 100%;
  /* text-transform: capitalize; */
  font-size: 2.25vw;
  /* text-shadow: 0px 0px 2px black; */
  text-shadow: 1px 1px 3px darkslategray;

  /* font-style: italic; */
}

.WorkComponent .Description p {
  color: black;
  font-size: 1.3vw;
  line-height: 1.3em;
  letter-spacing: 0.05em;
  /* text-shadow: 0px 0px 1px black; */
  /* text-shadow: 0px 0px 2px darkslategray; */

}