.App {
  font-family: 'Abel', sans-serif;
  max-width: 100vw;
  overflow: hidden;
}

.flex-center {
  display: flex;
  justify-content: center;
}

a:hover {
  text-decoration: none;
}
